.logo {
  letter-spacing: 0.3rem;
  font-size: 1.5rem;
  font-weight: 900;
}

.auth-container button {
  font-family: "Inter", sans-serif;
  border-radius: 6px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  padding: 1rem 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.02em;
  border: none;
}

.auth-container button:hover {
  color: rgba(255, 255, 255);
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 0px 10px);
  text-decoration: none;
}

/* Navbar hamburger tutorial */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

.nav-item a {
  text-decoration: none;
  color: black;
}

a:visited {
  color: black;
}

.navbar {
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5rem;
}

.nav-menu {
  display: flex;
  gap: 60px;
  color: black;
  align-items: center;
}

.menuNav {
  display: none;
}

.nav-link {
  transition: 0.7s ease;
}

.nav-link:hover {
  color: rgb(219, 57, 252);
  text-decoration: underline;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: black;
}

@media (max-width: 820px) {
  .navbar {
    justify-content: center;
  }
  .logo {
    font-size: 1.2rem;
  }

  .hamburger {
    display: block;
    position: fixed;
    left: 30px;
    top: 18px;
    z-index: 10;
    cursor: pointer;
  }

  .hamburger.showMenu .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburder.showMenu .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburder.showMenu .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 70px;
    gap: 0;
    flex-direction: column;
    min-width: 100%;
    text-align: center;
    transition: 0.3s;
    padding: 2rem;
  }

  .nav-item {
    padding: 1rem;
  }

  .nav-item a {
    font-size: 1.5rem;
  }

  .nav-menu.showMenu {
    background-color: whitesmoke;
    overflow-y: scroll;
    list-style: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 0;
    overflow: hidden;
    z-index: 9;
  }

  .auth-container button {
    padding: 0.8rem 1.3rem;
    font-size: 1rem;
  }
}
