.about-container {
  padding: 4rem;
  background-color: black;
  color: white;
  font-size: 2rem;
  min-height: 90%;
  margin: auto;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  justify-content: center;
}
.about-item {
  text-align: left;
  max-width: 100%;
  border-left: 2px solid rgb(255, 255, 255);
  padding-left: 1rem;
  margin: 0;
  line-height: 1.3;
}

.about-item p {
  font-size: 1.5rem;
}

.about-button {
  font-size: 3rem;
  color: white;
  min-height: 100%;
  display: flex;
  justify-content: center;
}

.about-button a {
  align-self: center;
  text-transform: uppercase;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  padding: 9px 18px;
  text-decoration: none;
}
.about-button a:visited {
  color: white;
}

.about-button a:hover{
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-decoration: none;
}

@media (max-width: 820px) {
  .about-container {
    grid-template-columns: 1fr;
    padding: 4rem 2rem;
    grid-gap: 1rem;
    font-size: 1.5rem;
  }
  .about-item p,
  .about-item h3 {
    margin: 0;
  }
  .about-item p {
    font-size: 1.2rem;
  }
}
