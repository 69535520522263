.main-container {
  display: flex;
  padding: 5% 15%;
  min-height: 80vh;
  max-width: 100%;
  gap: 2rem;
}

/* LEFT GRID SIDE */

.info-left {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: center;
}

/* Header Info CSS */
.title {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
  padding: 0 2rem;
}

.text {
  /* border: 2px solid tomato; */
  line-height: 1.5;
  margin: 0;
  padding: 0 2rem;
  font-size: 1.5rem;
  font-weight: 350;
}

.deposit {
  display: flex;
  align-items: center;
  margin-top: 4vh;
  justify-content: center;
  padding: 0px 4vw;
}

.input-container input {
  padding: 2px;
  text-align: center;
  font-size: 1rem;
  min-width: 100%;
}

.input-container {
  max-width: 100%;
  padding-right: 1rem;
}

/* RIGHT GRID SIDE */

.info-right {
  min-width: 40%;
  background-image: url(../../images/background.jpg);
  background-size: cover;
  border-radius: 0.5rem;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem;
  overflow: hidden;
  justify-content: center;
}

.info-right button {
  margin-top: 1rem;
}

.info-right a {
  color: black;
  font-size: 1rem;
}
.info-right a:hover {
  color: whitesmoke;
}

.info-right-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  min-width: 100%;
  justify-content: center;
}

.info-right-text h1 {
  font-size: 15rem;
  margin: 0;
}

.info-right-text p {
  font-weight: 500;
  font-size: 1.5rem;
}

.info-right-links {
  min-width: 100%;
  padding-bottom: 1rem;
}

.button-main {
  font-family: "Inter", sans-serif;
  border-radius: 6px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  padding: 1.3rem 2rem;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
  border: none;
  text-decoration: none;
}

.button-main:hover {
  color: rgba(255, 255, 255);
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 0px 40px);
  text-decoration: none;
}

/* Under 800px Media Queries */

@media (max-width: 1050px) {
  .info-left {
    text-align: center;
  }

  /* INFO-LEFT DEPOSIT CONTAINER */
  .deposit {
    display: block;
    padding: 0rem;
  }
  .info-left .title {
    font-size: 3rem;
  }
  .info-left .text {
    font-size: 1.7rem;
  }
  .input-container {
    padding: 0;
  }
  input {
    font-size: 0.8rem;
    min-width: 60%;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 820px) {
  .main-container {
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    gap: 2rem;
  }
  .info-left {
    padding: 0rem 2rem;
    text-align: center;
    min-height: 30vh;
  }
  .info-left .title {
    font-size: 2.5rem;
  }
  .info-left .text {
    font-size: 1.5rem;
  }

  .info-right {
    min-height: 50vh;
    min-width: 80%;
    margin-bottom: 2rem;
  }
  .info-right-text {
    display: block;
  }
  .info-right-text h1 {
    font-size: 13rem;
  }
  .info-right-text p {
    font-size: 1.7rem;
  }
  .info-right-links a {
    font-size: 1rem;
  }
}

@media (max-width: 450px) {
  .info-left {
    padding: 0;
  }
  .title {
    padding: 0;
  }
  .text {
    padding: 0;
  }
  .input-container {
    padding: 0 0.5rem;
  }
  .info-right-text h1 {
    font-size: 12rem;
  }
  .button-main {
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }
}
