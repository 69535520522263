.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.35);

  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 640px;
  background-color: #fff;
  border-radius: 12px;
}

.popup-inner .close-btn {
  position: absolute;
  top: 10px;
  right: 12px;
  border-radius: 6px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  padding: 8px 12px;
  font-size: 0.8rem;
  letter-spacing: 0.03em;
  border: none;
  /* margin-top: 56px; */
  text-decoration: none;
}
