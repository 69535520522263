.collection-section {
  max-width: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.collection-container {
  max-width: 80%;
  padding: 2rem;
  display: flex;
  border-radius: 2%;
  overflow: auto;
}

.card {
  /* border: 2px solid blue; */
  background-color: white;
  width: 240px;
  height: 300px;
  margin: 10px;
  border-radius: 18px;
}

.card-img {
  height: 75%;
  width: 240px;
}

.card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.collection-img-text {
  margin: 0;
  color: black;
  font-family: "Inter", sans-serif;
  text-align: left;
  font-size: 10px;
  height: 25%;
  border-left: 1px solid rgb(194, 201, 209);
  border-right: 1px solid rgb(194, 201, 209);
  border-bottom: 1px solid rgb(194, 201, 209);
  border-radius: 0px 0px 14px 14px;
}

.collection-img-text p {
  margin: 0;
  /* border: 1px solid green; */
  overflow-wrap: break-word;
  height: 40%;
}

.collection-img-text h3 {
  margin: 0;
  font-size: 14px;
  /* align-content: center; */
  text-align: center;
  padding-top: 20px;
  font-weight: 400;
  overflow-wrap: break-word;
}

.collection-text {
  font-size: 1.8rem;
}
