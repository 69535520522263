.vault-container {
  margin: auto;
  text-align: center;
  font-family: "Inter", sans-serif;

  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  min-height: 100vh;
  min-width: 100%;
  font-size: 1rem;
  background-image: url(../../images/background2.jpg);
  background-size: cover;
  overflow: auto;
}

.vault-container h2 {
  font-size: 1.5rem;
}

.vault-header {
  padding-top: 2rem;
  border-bottom: 4px solid whitesmoke;
}

.vault-content {
  padding: 4rem;
  line-height: 3.5rem;
  overflow: auto;
}

.vault-links {
  font-size: 1.5rem;
  font-family: "Inter", sans-serif;
  text-decoration: none;
  color: black;
}

.vault-links:hover {
  text-decoration: underline;
}
