.container {
  margin: auto;
  text-align: center;
  font-family: "Inter", sans-serif;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}

body {
  background-color: whitesmoke;
}
