.footer {
  min-height: 20vh;
  display: flex;
  justify-content: space-evenly;
  color: white;
  background-color: black;
  align-items: center;
  padding: 2rem;
  max-width: 100%;
  font-size: 2rem;
}

.footer img {
  padding: 1rem;
}

.footer img:hover {
  background-color: rgb(207, 145, 207);
  border-radius: 10px;
}

.footer h2 {
  padding-bottom: 1rem;
}

@media (max-width: 820px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    font-size: 1.5rem;
  }
}
